
import ScreenLoading from "components/ScreenLoading"
import useConversation from "containers/conversations/useConversation"
import OrganizationsContext from "containers/organizations/organizationsContext"
import { useContext, useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import "./Products.scss" 
import { Product, ProductMode } from "types"

interface IProductCardProps {
  product: Product,
  toggle: boolean
}

const Features = ({product, show}) => {
    const CheckMark = () => <span className="material-icons-outlined" style={{color: "green"}}>check</span>
    
    const XMark = () => <span className="material-icons-outlined" style={{color: "red"}}>close</span>

    return (
        <>
            <div className={`product-features ${show === true ? "open" : ""}`}>
                {
                    product?.desc?.length > 0
                        ?   <p>{product.desc}</p>
                        :   <></>
                }
                <div className="feature">
                    <p>Accounts</p>
                    <div>
                    {product.accounts !== -1 ? product.accounts : 'Unlimited'} 
                    </div>
                </div>
                <div className="feature">
                    <p>Users</p>
                    <div>
                        {product.users !== -1 ? product.users : "Unlimited"}
                    </div>
                </div>
                <div className="feature">
                    <p>Integrations</p>
                    <div>{product.integrations ? product.integrations : "None"}</div>
                </div>
                <div className="feature">
                    <p>Data History</p>
                    <div>
                        {product.data_history !== -1 ? product.data_history : 'Unlimited'} {product.data_history !== -1 && (product.data_history > 1 ? 'years' : 'year')}
                    </div>
                </div>
                <div className="feature">
                    <p>Priority Support</p>
                    <div>
                        {product.priority_support === true ? <CheckMark /> : <XMark/>}
                    </div>
                </div>
                <div className="feature">
                    <p>Email Support</p>
                    <div>
                        {product.email_support === true ? <CheckMark /> : <XMark/>}
                    </div>
                </div>
                <div className="feature">
                    <p>Onboarding</p>
                    <div>
                        {product.onboarding === true ? <CheckMark /> : <XMark/>}
                    </div>
                </div>
                <div className="feature">
                    <p>Custom Reports</p>
                    <div>
                        {product.custom_reports === true ? <CheckMark /> : <XMark/>}
                    </div>
                </div>
            </div>
        </>
    )
}

const ProductCard: React.FC<IProductCardProps> = ({ product, toggle }) => {
  const [showMore, setShowMore] = useState(false)

  const { currentOrganization } = useContext(OrganizationsContext)
  const history = useHistory()

  const { createConversation, loadingCreatingConversation, conversations } =
    useConversation(currentOrganization.id)

  const handleClick = (e) => {
    e.preventDefault()

    if (!product.can_purchase) {
      return;
    }

    if (product.has_custom_price && product.annual_price === 0) {
      const conversation = {
        visitorId: window.Intercom('getVisitorId'),
        message: `Hello, I would like to inquire about the ${product.title} product.`,
      }
      createConversation(conversation)
      return
    }

    const url = `/organization/${currentOrganization.slug}/billing/checkout?product=${product.id}&annual=${toggle}`
    history.push(url)
  }

  useEffect(() => {
    if (loadingCreatingConversation === false) {
      if ('errors' in conversations) {
        window.Intercom(
          'showNewMessage',
          `Hello, I would like to inquire about the ${product.title} product.`
        )
        return
      }

      if ('body' in conversations) {
        window.Intercom('show')
      }
    }
  }, [loadingCreatingConversation, conversations, product.title])

  const formatPrice = (amount: number) => {
    const formatted = amount
      .toLocaleString('en-US', { style: 'currency', currency: 'EUR' })
      .slice(1)
    if (formatted.slice(-3) === '.00') {
      return formatted.slice(0, -3)
    }
    return formatted
  }

  const handlePrice = () => {
    if (product.mode === ProductMode.SUBSCRIPTION) {
      if (product.annual_price !== null) {
        if (product.has_custom_price === true && product.annual_price !== 0) {
          return formatPrice(parseInt(product.annual_price?.toLocaleString()) / 12)
        } else if (product.has_custom_price === true) {
          return 'Custom'
        }
    
        return formatPrice(
          toggle ? parseInt(product.annual_price.toLocaleString()) * 12 : product.monthly_price! // *12 because we bill annually the whole sum
        )
      }
    } else {
      if (product.fixed_price) 
      return formatPrice(product.fixed_price)
    }
  }

  const handleButton = () => {
    if (loadingCreatingConversation === true) {
      return <ScreenLoading spinnerWidth="sm" spinnerColor="white" />
    }

    if (!product.can_purchase) {
      return "Purchased"
    }

    if (product.annual_price === 0) {
      return 'Contact us'
    }

    return 'Buy ' + product.title
  }

  return (
    <>
      <div className="product-card" data-featured={product.is_most_popular}>
        <div className="product-header">
          <h1 className="product-title">{product.title}</h1>
          <div
            className={`product-price ${product.mode === ProductMode.PAYMENT || (product.has_custom_price && product.annual_price) === 0 ? 'custom' : ''} ${product.mode === ProductMode.SUBSCRIPTION ? (toggle ? 'annually' : 'monthly') : "custom"}`}
          >
            {handlePrice()}
          </div>
          {product.has_custom_price === false ? (
            <p className="product-reccurring">
              {product.mode === ProductMode.SUBSCRIPTION ? "Billed " + (toggle ? 'annually' : 'monthly') : "Billed once"}
            </p>
          ) : (
            <p className="product-reccurring">Billed annually</p>
          )}
        </div>
        <div className="product-body">
          <Features product={product} show={showMore} />
          <button
            className="show-button"
            onClick={() => setShowMore(!showMore)}
          >
            Show {showMore === true ? 'less' : 'more'}
            <span
              className={`material-icons-outlined ${
                showMore === true ? 'rotate' : ''
              }`}
            >
              keyboard_arrow_down
            </span>
          </button>

          <div className="button-wrapper">
            <div className={`product-button ${!product.can_purchase ? 'disabled' : ''}`}>
              <button onClick={handleClick}>{handleButton()}</button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ProductCard
